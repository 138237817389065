import React from 'react'
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>Success Occupational Therapy | We come to you</title>
                <meta name="keywords" content="occupational therapy, what is occupational therapy, occupational therapy western australia, western australia, perth, jandakot, cockburn, senior, stroke, independant living, aged care, ndis, medicare, icwa, dva" />
                <meta name="description" content="Success Occupational Therapy is a Western Australia busness providing services to the community. Whether recovering from an illness or something as serious as a stroke, participating in and enjoying life’s daily activities are essential steps in the recovery process. We want to make a positive difference. We see every client experience as another chance to help restore the physical and emotional quality of life for those we serve." />
                <meta name="author" content="Kylie Bell"></meta>
                <meta property="og:title" content="Success Occupational Therapy: We come to you"></meta>
                <meta name="twitter:card" content="Success Organisational Therapy"></meta>
                <meta property="og:image" content="https://res.cloudinary.com/dev-empty/image/upload/v1593069801/explore-learning.jpg" />
                <meta property="og:locale" content="en_AU" />
            </Helmet>
        </div>
    )
}

export default SEO
